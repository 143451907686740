
import { defineComponent, computed, WritableComputedRef } from "vue";
import PrintShare from "../store/modules/PrintShare/printShare";

export default defineComponent({
  name: "Snackbar",
  setup () {
    const isSnackbarVisible: WritableComputedRef<boolean> = computed({
      get (): boolean {
        return PrintShare.getSnackbarVisibility;
      },
      set (newValue: boolean): void {
        PrintShare.toggleSnackbar(newValue);
      },
    });

    const message = computed(() => {
      return PrintShare.getSuccessMessage;
    });

    return {
      isSnackbarVisible, message,
    };
  },
});
